import { Component } from '@angular/core';

declare var qtLoad: any;
declare var magicpdf_entry:any;

@Component({
  selector: 'app-qt-wasm',
  templateUrl: './qt-wasm.component.html',
  styleUrls: ['./qt-wasm.component.scss']
})
export class QtWasmComponent {

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const spinner = document.querySelector('#qtspinner2');
    const screen:any = document.querySelector('#screen2');
    const status:any = document.querySelector('#qtstatus2');

    const showUi = (ui:any) => {
      [spinner, screen].forEach((element:any) => element.style.display = 'none');
      if (screen && screen === ui)
        screen.style.position = 'default';
      ui.style.display = 'block';
    }

    try {
      showUi(spinner);
      status.innerHTML = 'Loading...';

      console.log(magicpdf_entry)

      const instance = await qtLoad({
        qt: {
          onLoaded: () => showUi(screen),
          onExit: (exitData:any) => {
            status.innerHTML = 'Application exit';
            status.innerHTML += exitData.code !== undefined ? ` with code ${exitData.code}` : '';
            status.innerHTML += exitData.text !== undefined ? ` (${exitData.text})` : '';
            showUi(spinner);
          },
          entryFunction: magicpdf_entry,
          containerElements: [screen],
        }
      });
    } catch (e:any) {
      console.error(e);
      console.error(e.stack);
    }
  }

}
