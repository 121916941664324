import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { OverlayService, LangService, UserInfoService, ApiService, StorageService, SnackbarService } from 'src/services';
import { Res } from 'src/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'HUAHAN TECHNOLOGY';
  loading: boolean = false;

  constructor(
    private overlayService: OverlayService,
    private titleService: Title,
    private translate: TranslateService,
    private langService: LangService, // 注入 LangService
    private userInfoService: UserInfoService,
    private apiService: ApiService,
    private storage: StorageService,
    private snackbarService: SnackbarService,
    private router: Router
  ) {
    this.langService.transLateInit(); // 调用 LangService 的初始化方法
    this.translate.onLangChange.subscribe(event => {
      this.langService.translate('SITE_TITLE').subscribe(res => {
        this.titleService.setTitle(res)
      })
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.overlayService.overlayRef?.hasAttached()) this.overlayService.handleClickOutside(event);
  }

  async ngOnInit() {
    await this.checkWxLoginCode();
    await this.userInfoService.fetchAndSetUserInfo();
    // window.addEventListener('message', this.receiveMessage.bind(this), false);
  }

  // receiveMessage(event: MessageEvent): void {
  //   // 可验证 event.origin，以确保消息来源安全
  //   // if (event.origin !== 'https://open.weixin.qq.com') return;

  //   const { code, state } = event.data;
  //   if (code) {
  //     console.log('收到微信登录的 code:', code);
  //     // 在这里进一步处理登录逻辑，比如用 code 发送给服务器验证
  //   }
  // }

  // ngOnDestroy(): void {
  //   // 组件销毁时移除事件监听器
  //   window.removeEventListener('message', this.receiveMessage.bind(this));
  // }

  ngAfterViewInit() {
    this.langService.translate('SITE_TITLE').subscribe(res => {
      this.titleService.setTitle(res)
    })
  }

  async checkWxLoginCode() {

    // const urlParams = new URLSearchParams(window.location.search);
    // const code = urlParams.get('code');

    // const queryParams = new URLSearchParams(window.location.search);
    // const code = queryParams.get('code');
    // if (!code) return;

    // // 移除 code 参数
    // queryParams.delete('code');
    // const newUrl = queryParams.toString()
    //   ? `${window.location.pathname}?${queryParams.toString()}`
    //   : window.location.pathname;
    // console.log("Generated URL:", newUrl);  // 检查生成的 URL

    // // 使用 Angular Router 导航来更新 URL
    // this.router.navigate([], {
    //   queryParams: queryParams.toString() ? { state: queryParams.get('state') } : {},
    //   queryParamsHandling: 'merge',
    //   replaceUrl: true
    // });

    // await this.withLoading(async () => {
    //   const body = { code: code, platform_id: 1 };
    //   const res: Res = await this.apiService["wxLogin"](body);
    //   if (res.status !== 'success') throw res;
    //   this.snackbarService.open("登录成功"); // 使用服务
    //   this.storage.setItem('token', res.result);
    //   this.storage.setItem('qt-v0-HuaHan-Instrument-Web/token', res.result);
    //   window.close();
    // });
  }

  async withLoading(asyncFunc: () => Promise<any>) {
    try {
      this.loading = true;
      await asyncFunc();
    } catch (error: any) {
      console.log(error)
      this.snackbarService.open("something went wrong!"); // 使用服务
    } finally {
      this.loading = false;
    }
  }

}
