import { Component } from '@angular/core';
import { ScreenService } from 'src/services/cdk/screen.service'
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';  // 引入 Router

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {

  private subscriptions: Subscription[] = [];
  screenType: string = "desktop";

  constructor(
    private screenService: ScreenService,
    private router: Router  // 注入 Router
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.screenService.onResize$.subscribe(size => {
        this.screenType = size < 1200 ? "mobile" : "desktop";
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  handleButtonClick() {
    this.router.navigate(['/porcelain']);  // 跳转到 'porcelian' 路由
  }

}