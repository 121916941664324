import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';

import { NgmaterialModule } from '../module/ngmaterial/ngmaterial.module';

import { HeaderComponent } from '../components/header/header.component';
import { MenuComponent } from '../components/menu/menu.component';
import { FooterComponent } from '../components/footer/footer.component';
import { ContentComponent } from '../components/content/content.component';
import { MenuTriggerComponent } from '../components/menu-trigger/menu-trigger.component';
import { SmsVerificationComponent } from '../components/sms-verification/sms-verification.component';
import { NumberSpinnerComponent } from '../components/number-spinner/number-spinner.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { SoftwareCardComponent } from '../components/software-card/software-card.component'
import { ExpansionPanelComponent } from '../components/expansion-panel/expansion-panel.component'
import { SakuraComponent } from '../components/sakura/sakura.component'
import { ImageComparisonComponent } from '../components/image-comparison/image-comparison.component'
import { BacktopComponent } from '../components/backtop/backtop.component'
import { RadioGroupComponent } from 'src/components/radio-group/radio-group.component';
import { PaypalComponent } from 'src/components/pay/paypal/paypal.component';
import { WechatComponent } from 'src/components/pay/wechat/wechat.component';
import { AliComponent } from 'src/components/pay/ali/ali.component';
import { ProfileComponent } from 'src/components/profile/profile.component';
import { MarkdownRendererComponent } from 'src/components/markdown-renderer/markdown-renderer.component'; 
import { ProductDropdownmenuComponent } from 'src/components/header/product-dropdownmenu/product-dropdownmenu.component';
import { HeaderNavComponent } from 'src/components/header/header-nav/header-nav.component';
import { QtWasmComponent } from 'src/components/qt-wasm/qt-wasm.component';
import { QtWebasmComponent } from 'src/components/qt-webasm/qt-webasm.component';

import { HomeComponent } from '../pages/home/home.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { HelpComponent } from '../pages/help/help.component'
import { PorcelainComponent } from '../pages/porcelain/porcelain.component'
import { TrialComponent } from 'src/pages/trial/trial.component'
import { PriceComponent } from 'src/pages/price/price.component';
import { PersonalComponent } from 'src/pages/personal/personal.component';

import { AuthComponent } from 'src/pages/auth/auth.component';
import { LoginComponent } from 'src/pages/auth/login/login.component';
import { RegisterComponent } from 'src/pages/auth/register/register.component';
import { WxloginComponent } from 'src/pages/auth/wxlogin/wxlogin.component';
import { GoogleloginComponent } from 'src/pages/auth/googlelogin/googlelogin.component';
import { PrivacyComponent } from 'src/pages/privacy/privacy.component';
import { ServiceTermComponent } from 'src/pages/service-term/service-term.component';

import { WechatAuthComponent } from 'src/pages/oauth/authorized/wechat/wechat.component';

import { AuthInterceptorModule } from 'src/module/auth-interceptor/auth-interceptor.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    ContentComponent,
    MenuTriggerComponent,
    SmsVerificationComponent,
    NumberSpinnerComponent,
    LoadingComponent,
    SoftwareCardComponent,
    ExpansionPanelComponent,
    SakuraComponent,
    ImageComparisonComponent,
    BacktopComponent,
    RadioGroupComponent,
    PaypalComponent,
    WechatComponent,
    AliComponent,
    ProfileComponent,
    MarkdownRendererComponent,
    ProductDropdownmenuComponent,
    HeaderNavComponent,
    QtWasmComponent,
    QtWebasmComponent,

    AuthComponent,
    LoginComponent,
    RegisterComponent,
    WxloginComponent,
    GoogleloginComponent,

    HomeComponent,
    ContactComponent,
    HelpComponent,
    PorcelainComponent,
    TrialComponent,
    PriceComponent,
    PersonalComponent,
    PrivacyComponent,
    ServiceTermComponent,

    WechatAuthComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgmaterialModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MarkdownModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorModule, multi: true }
  ]
})
export class AppModule { }
