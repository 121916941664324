<div class="summary vertical-center flex">

    <ng-container *ngIf="screenType=='desktop'; else notMobile">
        <div class="circle-bg circle-bg-left"></div>
        <div class="circle-bg circle-bg-right"></div>
    </ng-container>
    <ng-template #notMobile>
        <div class="gradient-bg"></div>
    </ng-template>

    <div class="summary-title">
        <p class="headline-1">
            <span class="gradient-text">一站式</span><span>打印工具集</span>
        </p>
        <div class="headline-2 vertical-center flex">
            <div class="line"></div>
            <div class="dot"></div>
        </div>
        <p class="headline-3">
            无论您是需要高效率的排版、提高色彩表现力、PDF的编辑还是可变数据的打印，ePrint.ink都为您提供了最佳工具。
        </p>
        <p class="headline-4">
            <button class="font-size-large contact-button" mat-raised-button routerLink="/contact">联系我们</button>
        </p>
    </div>

    <div class="software flex">

        <app-software-card [headlineOne]="'青华'" [headlineTwo]="'喷绘RIP软件'" [headlineThree]="'详细了解 青华'"
            [buttonText]="'即将上线，敬请期待'" [buttonUseable]="false" [logoClass]="'soft-c-logo'"
            (buttonClicked)="handleButtonClick()"></app-software-card>

        <app-software-card [headlineOne]="'丹书'" [headlineTwo]="'可变数据打印软件'" [buttonText]="'即将上线，敬请期待'"
            [buttonUseable]="false" [logoClass]="'soft-m-logo'"></app-software-card>

        <app-software-card [headlineOne]="'丰芒'" [headlineTwo]="'全自动排版软件'" [buttonText]="'即将上线，敬请期待'"
            [buttonUseable]="false" [logoClass]="'soft-y-logo'"></app-software-card>

        <app-software-card [headlineOne]="'玄玉'" [headlineTwo]="'二维码设计软件'" [buttonText]="'即将上线，敬请期待'"
            [buttonUseable]="false" [logoClass]="'soft-k-logo'"></app-software-card>

    </div>

</div>

<div class="intro">
    <div class="flex intro-title">
        <div class="line left-line"></div>
        <p>产品介绍</p>
        <div class="line right-line"></div>
    </div>

    <div class="intro-area flex">
        <div class="gradient-box gradient-box-c">
            <div class="interface-image interface-image-c">
            </div>
        </div>
        <div class="intro-content">
            <div class="intro-textarea">
                <p class="headline-1">青华 网页版</p>
                <p class="headline-2">适用于广告打印、T恤印花等应用的RIP软件，提供了手动和自动排版、自动构建专色通道和色彩管理等实用功能。</p>
                <div class="line"></div>
                <div class="headline-3 flex">
                    <!-- <p class="with-arrow">开始免费使用 青华</p> -->
                    <p class="with-arrow" (click)="handleButtonClick()">详细了解 青华</p>
                </div>
            </div>
        </div>
    </div>

    <div class="intro-area flex">
        <div class="gradient-box gradient-box-m">
            <div class="interface-image interface-image-m">
            </div>
        </div>
        <div class="intro-content">
            <div class="intro-textarea">
                <p class="headline-1">丹书 网页版</p>
                <p>适用于包装、印刷行业的设计、排版和打印的综合性RIP软件，支持多页PDF、图形设计、文字打印优化以及可变数据打印等。</p>
                <div class="line"></div>
                <div class="headline-3 flex">
                    <p class="with-arrow">即将上线，敬请期待</p>
                    <!-- <p class="with-arrow">详细了解 丹书</p> -->
                </div>
            </div>
        </div>
    </div>

    <div class="intro-area flex">
        <div class="gradient-box gradient-box-y">
            <div class="interface-image interface-image-y">
            </div>
        </div>
        <div class="intro-content">
            <div class="intro-textarea">
                <p class="headline-1">丰芒 网页版</p>
                <p>适用于广告、包装等行业的自动排版工作流软件，是业界速度最快的异形套排解决方案，自动识别轮廓、自动优化切割路径、自动排版、自动输出等，实现了无人值守工作流。</p>
                <div class="line"></div>
                <div class="headline-3 flex">
                    <p class="with-arrow">即将上线，敬请期待</p>
                    <!-- <p class="with-arrow">详细了解 丰芒</p> -->
                </div>
            </div>
        </div>
    </div>

    <div class="intro-area flex">
        <div class="gradient-box gradient-box-k">
            <div class="interface-image interface-image-k">
            </div>
        </div>
        <div class="intro-content ">
            <div class="intro-textarea">
                <p class="headline-1">玄玉 网页版</p>
                <p>适用于包装、电商等行业条形码、二维码等可变数据的设计与打印软件，支持多种数据源的排序和过滤，并集成了强大的艺术二维码设计功能。</p>
                <div class="line"></div>
                <div class="headline-3 flex">
                    <p class="with-arrow">即将上线，敬请期待</p>
                    <!-- <p class="with-arrow">详细了解 玄玉</p> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="technical vertical-center flex">
    <div class="tech-banner vertical-center flex">
        <p>技术支持</p>
    </div>
    <div class="flex tech-title">
        <div class="line left-line"></div>
        <p>为您提供最佳解决方案</p>
        <div class="line right-line"></div>
    </div>

    <div class="flex tech-content">
        <div class="advantage vertical-center flex">
            <div class="advantage-image advantage-image-1"></div>
            <div class="advantage-text">高度安全</div>
            <div class="advantage-detail">软件采用高加密文件保护，无需担心盗版等干扰工作等等等。</div>
        </div>
        <div class="advantage vertical-center flex">
            <div class="advantage-image advantage-image-2"></div>
            <div class="advantage-text">优质性能</div>
            <div class="advantage-detail">软件采用高加密文件保护，无需担心盗版等干扰工作等等等。</div>
        </div>
        <div class="advantage vertical-center flex">
            <div class="advantage-image advantage-image-3"></div>
            <div class="advantage-text">全天客服</div>
            <div class="advantage-detail">软件采用高加密文件保护，无需担心盗版等干扰工作等等等。</div>
        </div>
    </div>

</div>

<div class="contact vertical-center flex">
    <div class="circle-bg circle-bg-left"></div>
    <div class="circle-bg circle-bg-right"></div>

    <div class="contact-content vertical-center flex">
        <p class="headline-1">准备好和我们一起发展了吗？</p>
        <p class="headline-2">你想要的，应有尽有你想要的，应有尽有你想要的，应有尽有你想要你想要的，应有尽有你想要</p>
        <button class="contact-button" mat-raised-button routerLink="/contact">联系我们</button>
    </div>

</div>