import { Component } from '@angular/core';

declare var WxLogin: any;

@Component({
  selector: 'app-wxlogin',
  templateUrl: './wxlogin.component.html',
  styleUrls: ['./wxlogin.component.scss']
})
export class WxloginComponent {

  ngAfterViewInit() {
    const loginContainer = document.getElementById("wxlogin-qrcode");
    if (loginContainer) {
      console.log("login")
      // 确保元素存在后再设置innerHTML属性
      const obj = new WxLogin({
        self_redirect: false,
        id: "wxlogin-qrcode",
        appid: "wx5aa70a070b23fec8", // 替换为您的应用ID
        scope: "snsapi_login",
        // redirect_uri: encodeURIComponent("https://eprint.ink"), // 替换为您的回调URL
        // redirect_uri: encodeURIComponent("http://localhost:4200/home"), // 替换为您的回调URL
        redirect_uri: encodeURIComponent("https://52c3-116-21-13-145.ngrok-free.app/oauth/authorized/wechat"),
        state: "1363258963",
        style: "",
        href: ""
      });
      console.log("obj", obj)
    }
  }

}
